import React from 'react'
import BackIcon from '../images/back-icon.svg'

export default function DropDownBtn(props) {
    return (
        <button className={`icon-btn drop-down-btn ${props.actively ? 'drop-down-btn__transform' : null}`}>
            <img className="close" src={BackIcon} alt="close" onClick={props.onClick} aria-hidden="true" />
        </button>
    )
}
