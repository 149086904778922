import React, { useState } from 'react'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import DropDownBtn from '../components/DropDownBtn'

import LocationIcon from '../images/location-icon.svg'
import MailIcon from '../images/mail-icon.svg'
import PhoneIcon from '../images/phone-icon.svg'

export default function Contacts() {
    const [stateContactsMenu, setStateContactsMenu] = useState(true)
    const mapLink = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47972.27885313976!
    2d69.2391543440503!3d41.28128385772553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s
    0x38ae8a9c1f1db005%3A0xc67972be35765523!2zNDHCsDE2JzA1LjAiTiA2OcKwMTUnMjcuNyJF!5e0!3m2!1s
    ru!2s!4v1609224543466!5m2!1sru!2s`

    return (
        <>
            <NavBar addClass='no-shadow' />
            <section className={`contacts ${stateContactsMenu ? "drop-down__hide" : ""}`}>
                <div className="contacts-head">
                    <h2>Контакты</h2>
                    <DropDownBtn actively={stateContactsMenu} onClick={() => setStateContactsMenu(!stateContactsMenu)} />
                </div>
                <ul className="contacts-description">
                    <li><img src={LocationIcon} alt="" /><address>г.Ташкент, Кичик халка йули, ул. Хамза Умарова, 8</address></li>
                    <li><img src={MailIcon} alt="" /><a href="mailto:info@mikroelektronika.plus">info@mikroelektronika.plus</a></li>
                    <li>
                        <img src={PhoneIcon} alt="" />
                        <div>
                            <a href="tel:+998 (93) 222-22-25">+998 (93) 222-22-25,</a>
                            <br />
                            <a href="tel:+998 (97) 157-58-78">+998 (97) 157-58-78</a>
                        </div>
                    </li>
                </ul>
            </section>
            <div className="mapContainer">
                <iframe src={mapLink} title='Место нахождение компании' aria-hidden='false'></iframe>
            </div>
            <Footer className="footer-contacts-page" />
        </>
    )
}
